export const dropdownStyleConfig = {
	control: (baseStyles) => ({
		...baseStyles,
		backgroundColor: 'gray.800',
		borderColor: 'inherit',
	}),
	input: (baseStyles) => ({
		...baseStyles,
		color: 'whiteAlpha.900',
	}),
	placeholder: (baseStyles) => ({
		...baseStyles,
		color: 'whiteAlpha.900',
	}),
	singleValue: (baseStyles) => ({
		...baseStyles,
		color: 'whiteAlpha.900',
	}),
	menuList: (baseStyles) => ({
		...baseStyles,
		backgroundColor: '#2D3748',
		border: '1px solid gray',
	}),
	option: (baseStyles, state) => ({
		...baseStyles,
		backgroundColor: state.isSelected ? 'rgb(67, 153, 255)' : 'inherit',

		':hover': {
			backgroundColor: state.isSelected ? 'rgb(67, 153, 255)' : 'rgb(128, 128, 128)',
		},
	}),
	multiValue: (baseStyles) => ({
		...baseStyles,
		backgroundColor: 'rgb(204, 204, 204)',
		borderRadius: '5px',
		color: 'black',
	}),
}
