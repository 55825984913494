import { Modal, ModalBody, ModalContent, ModalOverlay, useColorMode } from '@chakra-ui/react'
import { getModalDevices } from 'api/devices'
import { dropdownStyleConfig } from 'globals/dropdownStyleConfig'
import { useNavigate } from 'react-router-dom'
import AsyncSelect from 'react-select/async'

export const GlobalSearchModal = ({ isOpen, onClose }) => {
	const { colorMode } = useColorMode()
	const navigate = useNavigate()

	const getAsyncDevices = async (search) => {
		const response = await getModalDevices(search)
		return response.data.map((device) => ({
			label: `${device.deviceName} - ${device.serialNumber}`,
			value: device.serialNumber,
			id: device.solHelloDeviceId,
		}))
	}

	const dropdownStyle = colorMode === 'dark' && dropdownStyleConfig

	return (
		<Modal isOpen={isOpen} onClose={onClose} size='3xl'>
			<ModalOverlay backdropFilter='auto' backdropBlur='2px' />
			<ModalContent>
				<ModalBody>
					<AsyncSelect
						isClearable
						placeholder='Search devices'
						styles={dropdownStyle}
						name='devices-modal'
						loadOptions={getAsyncDevices}
						onChange={(e, actionMeta) => {
							if (actionMeta.action === 'select-option') {
								onClose()
								navigate(`devices/${e.value}-${e.id}`)
							}
						}}
					/>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}
