const getUniqueById = (arr1, arr2) => {
	const idCounts = new Map()

	// Count the occurrence of each id in both arrays
	for (const item of arr1.concat(arr2)) {
		const count = idCounts.get(item.id) || 0
		idCounts.set(item.id, count + 1)
	}

	// Filter out items with non-unique ids
	const uniqueItems = arr1.concat(arr2).filter((item) => idCounts.get(item.id) === 1)

	return uniqueItems
}

export default getUniqueById
