import { useAuth } from 'oidc-react'
import { useNavigate } from 'react-router-dom'

const Signin = () => {
	const { userManager } = useAuth()
	const navigate = useNavigate()
	userManager.events.addUserLoaded(() => navigate(-1))
	return <></>
}

export default Signin
