export const fetchMethods = {
	GET: 'GET',
	POST: 'POST',
	PUT: 'PUT',
	PATCH: 'PATCH',
	DELETE: 'DELETE',
}

export const pageTitles = {
	DASHBOARD: 'DASHBOARD',
	USERS: 'USERS',
	PARTNERS: 'PARTNERS',
	PARTNER_DETAILS: 'PARTNER DETAILS',
	HOSPITALS: 'HOSPITALS',
	DEVICES: 'DEVICES',
	DEVICE_DETAILS: 'DEVICE DETAILS',
	DEVIGE_LOGS: 'DEVICE LOGS',
	OS_DEPLOYMENTS: 'OS DEPLOYMENTS',
	OS_CREATE_DEPLOYMENT: 'CREATE DEPLOYMENT',
	DEVICE_FLAVOUR: 'DEVICE FLAVOUR',
	DEVICE_APPS: 'DEVICE APPS',
	DEVICE_APPS_DETAILS: 'DEVICE APPS DETAILS',
	PROFILES: 'PROFILES',
	PROFILE_DETAILS: 'PROFILE DETAILS',
	CREATE_PROFILE: 'CREATE PROFILE',
	EDIT_PROFILE: 'EDIT PROFILE',
	HOSPITAL_FLOORS: 'HOSPITAL FLOORS',
	OS_RELEASES: 'OS RELEASES',
	APP_RELEASES: 'APP RELEASES',
	UPLOAD_MODELS: 'UPLOAD MODELS',
	ML_MODELS: 'ML MODELS',
}

export const userRoles = {
	SUPER_USER: 'hac.admin',
	DEVELOPER: 'hac.developer',
	SUPPORT: 'hac.support',
	PARTNER: 'hac.partner',
	HOSPITAL: 'hac.client',
	ADMIN: 'Admin',
	USER_PARTNER: 'Partner',
	CLIENT: 'Client',
}

export const navigationRoutes = {
	DASHBOARD: '/dashboard',
	USERS: '/users',
	PARTNERS: '/partners',
	HOSPITALS: '/hospitals',
	DEVICES: '/devices',
	DEVICE_LOGS: '/devicelogs',
	DEVICES_UPLOAD: '/devicesupload',
	PROFILES: '/profiles',
	HOSPITAL_FLOORS: '/hospital-floors',
	OS_DEPLOYMENTS: '/osdeployments',
	APP_DEPLOYMENTS: '/appdeployments',
	FLAVOUR: '/device-flavour',
	APPS: '/device-apps',
	OS_RELEASES: '/os-releases',
	APP_RELEASES: '/app-releases',
}

//Device
export const deviceTable = {
	deviceList: 'deviceList',
}

export const deviceDetailsCommands = {
	activeOfflineCommands: 'activeOfflineCommands',
}

export const deviceLogs = {
	allowList: 'allowList',
	commandLogs: 'commandLogs',
	liveConferences: 'liveConferences',
	pastConferenes: 'pastConferences',
	statusLogs: 'statusLogs',
	receivedLogs: 'receivedLogs',
	deviceLogsList: 'deviceLogsList',
	deviceLogsType: 'deviceLogsType',
}

export const deviceDetails = {
	deviceDetails: 'deviceDetails',
	treeView: 'treeView',
}

export const dashboard = {
	dashboardList: 'dashboardList',
}

//Users

export const usersTable = {
	usersList: 'usersList',
}

//Partners
export const partnersTable = {
	partnersList: 'partnersList',
}

export const partnerDetails = {
	partnerDetails: 'partnerDetails',
	partnersHospitals: 'partnersHospitals',
	mainOrganization: 'mainOrganization',
}

//Hospitals

export const hospitalsTable = {
	hospitalsList: 'hospitalsList',
}

export const hospitalsFLoorsTable = {
	hospitalFloors: 'hospitalFloors',
	hospitalPartnerFloors: 'hospitalPartnerFloors',
}

//OsDeployments

export const osDeploymentsTable = {
	osDeploymentsList: 'osDeploymentsList',
}

//DeviceFlavours

export const deviceFlavoursTable = {
	deviceFlavoursList: 'deviceFlavoursList',
}

export const deviceFlavourDetails = {
	deviceFlavourDetails: 'deviceFlavourDetails',
	deviceFlavourApps: 'deviceFlavourApps',
}

//DeviceApps

export const deviceAppsTable = {
	deviceAppsList: 'deviceAppsList',
}
export const deviceAppsDetails = {
	deviceAppsDetails: 'deviceAppsDetails',
	soloDeviceApp: 'soloDeviceApp',
}

//Profiles

export const profilesTable = {
	profilesList: 'profilesList',
}

export const profilesDetails = {
	profilesDetails: 'profilesDetails',
}
