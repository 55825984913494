import { fetchMethods } from 'enums'
import fetchData from 'helpers/fetchData'

export const getDevices = ({appRelease = '', deviceFamilyId = '', firmware = '', pageSize, pageIndex, partner = '', status = '', dateFrom = '',dateTo = '',},search = '') => {
	return () => fetchData( fetchMethods.GET,
			`v1/devices?pageSize=${pageSize}&currentPage=${pageIndex + 1}&search=${encodeURIComponent(search.trim())}&filters.deviceFamilyId=${deviceFamilyId}&filters.isOnline=${status}&filters.partnerId=${partner}&filters.buildNumber=${firmware}&filters.appversion=${appRelease}&filters.date=${dateFrom}&filters.dateTo=${dateTo}`
		)}

export const getModalDevices = (search='') => fetchData(fetchMethods.GET, `v1/devices?pageSize=10&search=${search}`)

export const getDeviceDetails = (serial, deviceId) => () => fetchData(fetchMethods.GET, `v1/devices/${serial}/details?deviceId=${deviceId}`)

export const getPopoverDeviceDetails = (serial) => fetchData(fetchMethods.GET, `v1/devices/${serial}/mdm-details`)

export const getDeviceTreeview = (id, companyId) => () => fetchData(fetchMethods.GET, `v1/devices/${id}/companies/${companyId}/location`)

export const getOngoingConferences = (solHelloId) => () => fetchData(fetchMethods.GET, `v1/devices/${solHelloId}/conferences/ongoing`)

export const getPastConferences = (serial) => () => fetchData(fetchMethods.GET, `v1/devices/${serial}/conferences/past`)

export const getReceivedLogs = (serial) => () => fetchData(fetchMethods.GET, `v1/devices/${serial}/logs?currentPage=1&pageSize=100`)

export const getStatusLogs = (serial) => () => fetchData(fetchMethods.GET, `v1/devices/${serial}/status-logs?currentPage=1`)

export const getCommandLogs = (solHelloId) => () => fetchData(fetchMethods.GET, `v1/devices/${solHelloId}/command-logs?pageSize=50`)

export const getAllowList = (solHelloId) => () => fetchData(fetchMethods.GET, `v1/devices/${solHelloId}/allowlist/logs`)

export const getActiveOfflineCommands = (id) => () => fetchData(fetchMethods.GET, `v1/devices/${id}/offline-commands/all`)

export const getDeviceFamily = () => fetchData(fetchMethods.GET, `v1/devices/families`)

export const getUnasignedDevicesDropdown = () => fetchData(fetchMethods.GET, `v1/devices/v1.1/unallocated`)

export const getUnassociatedDevices = () => fetchData(fetchMethods.GET, `v1/device-pools/unassociated-devices`)

export const createDevice = (formObj) => fetchData(fetchMethods.POST, `v1/devices`, JSON.stringify(formObj))

export const transferToPartner = (formObj) => () => fetchData(fetchMethods.POST, `v1/devices/re-transfer`, JSON.stringify(formObj))

export const transferToPartnerBulk = (formObj) => fetchData(fetchMethods.POST, `v1/devices/transfer`, JSON.stringify(formObj))

export const sendCommand = (type, command, dynamicData, reason, solHelloId) => {
	const url = `v1/devices/${solHelloId}/${type ? 'command' : 'offline-commands'}`
	return fetchData(fetchMethods.POST, url, JSON.stringify({ command, dynamicData, reason, deviceId: +solHelloId }))
}

export const editDeviceWithId = (formObj, deviceId) => fetchData(fetchMethods.PUT, `v1/devices/${deviceId}`, JSON.stringify(formObj))

export const deleteDeviceWithId = (deviceId) => () => fetchData(fetchMethods.DELETE, `v1/devices/${deviceId}`)

export const removeFromPartner = (deviceId) => () => fetchData(fetchMethods.DELETE, `v1/devices/device-of-partner/${deviceId}`)
