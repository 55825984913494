import { HStack, ListItem, Text, Tooltip, useColorMode, useColorModeValue } from '@chakra-ui/react'
import { useAtom } from 'jotai'
import { NavLink } from 'react-router-dom'
import { isNavigationExpanded } from 'store'

const NavigationItem = ({ label, route, icon, hasTitle }) => {
	const [expandNavigation] = useAtom(isNavigationExpanded)

	const { colorMode } = useColorMode()
	const color = useColorModeValue('#3355c2', 'teal.200')
	const sidebarBackgroundColor = useColorModeValue('#ebf0fe', 'teal.900')

	const activeStyle = {
		backgroundColor: sidebarBackgroundColor,
		color: color,
		padding: '7px',
		rounded: 'lg',
	}

	return (
		<>
			{expandNavigation && hasTitle && (
				<Text
					pt={2}
					fontSize='xs'
					fontWeight='600'
					letterSpacing='0.5px'
					textTransform='uppercase'
					color={colorMode === 'dark' ? 'teal.200' : 'blackAlpha.900'}>
					{hasTitle}
				</Text>
			)}
			<ListItem>
				<Tooltip hasArrow label={expandNavigation ? '' : label} placement='right'>
					<NavLink
						to={route}
						children={({ isActive }) => (
							<HStack spacing='3' justifyContent={expandNavigation ? '' : 'center'} {...(isActive && activeStyle)}>
								<Text className='material-icons'>{icon}</Text>
								{expandNavigation && <Text>{label}</Text>}
							</HStack>
						)}
					/>
				</Tooltip>
			</ListItem>
		</>
	)
}

export default NavigationItem
