import {Flex, Text} from '@chakra-ui/react'

export const ProgressBar = ({options = []}) => {
    return (
        <Flex flexDirection='column' gap='2'>
            <Flex>
                {options.map((option) => (
                    <Flex
                        key={option.color}
                        bg={option.color}
                        width={option.percentage}
                        height='4'
                        alignItems='center'
                        justifyContent='center'
                        style={option.style}>
                        <Text textAlign='center' color='white'>
                            {option.percentage}
                        </Text>
                    </Flex>
                ))}
            </Flex>
            <Flex justifyContent='space-between'>
                {options.map((option) => (
                    <Flex key={option.color} gap='1' fontSize='sm'>
                        <Text color={option.color}>{option.deploymentType}</Text>
                        <Text fontWeight='medium'>{option.value}</Text>
                    </Flex>
                ))}
            </Flex>
        </Flex>
    )
}
