import { userRoles } from 'enums'

export const routes = [
	{
		route: '/dashboard',
		icon: 'dashboard',
		label: 'Dashboard',
		roles: [userRoles.SUPER_USER, userRoles.PARTNER, userRoles.HOSPITAL],
	},
	{
		route: '/users',
		icon: 'person',
		label: 'Users',
		roles: [userRoles.SUPER_USER, userRoles.PARTNER, userRoles.HOSPITAL],
		hasTitle: 'User Management',
	},
	{
		route: '/partners',
		icon: 'apartment',
		label: 'Partners',
		roles: [userRoles.SUPER_USER],
	},
	{
		route: '/hospitals',
		icon: 'local_hospital',
		label: 'Hospitals',
		roles: [userRoles.SUPER_USER, userRoles.PARTNER],
	},
	{
		route: '/devices',
		icon: 'radio_button_checked',
		label: 'Devices',
		roles: [userRoles.SUPER_USER, userRoles.PARTNER, userRoles.HOSPITAL],
		hasTitle: 'Provisioning',
	},
	{
		route: '/devicelogs',
		icon: 'web_stories',
		label: 'Device Logs',
		roles: [userRoles.SUPER_USER, userRoles.PARTNER, userRoles.HOSPITAL],
	},
	{
		route: '/profiles',
		icon: 'folder_shared',
		label: 'Profiles',
		roles: [userRoles.SUPER_USER, userRoles.PARTNER, userRoles.HOSPITAL],
	},
	{
		route: '/hospital-floors',
		icon: 'layers',
		label: 'Hospital Floors',
		roles: [userRoles.SUPER_USER, userRoles.HOSPITAL],
	},
	{
		route: '/osdeployments',
		icon: 'dns',
		label: 'OS Deployments',
		roles: [userRoles.SUPER_USER, userRoles.PARTNER, userRoles.HOSPITAL],
	},
	{
		route: '/device-flavour',
		icon: 'settings_input_composite',
		label: 'Device Flavours',
		roles: [userRoles.SUPER_USER],
		hasTitle: 'Device Management',
	},
	{
		route: '/device-apps',
		icon: 'apps',
		label: 'Apps',
		roles: [userRoles.SUPER_USER],
	},
	{
		route: '/os-releases',
		icon: 'published_with_changes',
		label: 'OS Releases',
		roles: [userRoles.SUPER_USER],
	},
	{
		route: '/app-releases',
		icon: 'apps_outage',
		label: 'App Releases',
		roles: [userRoles.SUPER_USER],
	},
	{
		route: '/upload-mlmodels',
		icon: 'drive_folder_upload',
		label: 'Upload Models',
		hasTitle: 'Machine learning Models',
		roles: [userRoles.SUPER_USER],
	},
	{
		route: '/mlmodels',
		icon: 'model_training',
		label: 'ML Models',
		roles: [userRoles.SUPER_USER],
	},
]
