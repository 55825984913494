import { memo, useMemo } from 'react'
import { VStack, UnorderedList, useColorMode } from '@chakra-ui/react'
import { useAtom } from 'jotai'
import { isNavigationExpanded } from 'store'
import { useAuth } from 'oidc-react'
import { routes } from 'layout/roleRoutes'
import NavigationItem from 'layout/NavigationItem'

const MemoizedNavItem = memo(NavigationItem)

const Navigation = () => {
	const [expandNavigation] = useAtom(isNavigationExpanded)
	const { colorMode } = useColorMode()
	const {
		userData: { profile },
	} = useAuth()

	const filteredRoutes = useMemo(
		() => routes.filter((route) => route.roles.some((role) => profile.role.includes(role))),
		[profile.role]
	)

	return (
		<VStack
			w='full'
			overflow='auto'
			overflowX='hidden'
			alignItems={expandNavigation ? '' : 'center'}
			css={{
				'&::-webkit-scrollbar': {
					width: '4px',
					height: '4px',
				},
				'&::-webkit-scrollbar-track': {
					width: '6px',
					height: '6px',
				},
				'&::-webkit-scrollbar-thumb': {
					background: 'gray',
					borderRadius: '24px',
				},
			}}>
			<UnorderedList
				listStyleType='none'
				spacing={4}
				m={0}
				color={colorMode === 'light' && 'gray.500'}
				fontWeight='500'
				letterSpacing='wider'
				mr={expandNavigation ? '4' : 0}>
				{filteredRoutes.map((route) => (
					<MemoizedNavItem
						key={route.route}
						hasTitle={route.hasTitle}
						icon={route.icon}
						label={route.label}
						route={route.route}
					/>
				))}
			</UnorderedList>
		</VStack>
	)
}

export default Navigation
