import { BASE_URL } from 'constants/authConstants'
import { userAuthorizationData } from '../globals'

const fetchData = (method, url, body) => {
	return fetch(`${BASE_URL}/${url}`, {
		method,
		body,
		headers: {
			Authorization: `Bearer ${userAuthorizationData.access_token}`,
			'Content-Type': 'application/json',
		},
	})
		.then(async (response) => {
			if (!response.ok) {
				const responseError = await response.json()
				console.error(responseError.code, responseError.message)
				throw Error(responseError?.message)
			} else {
				return response.json()
			}
		})
		.then((resolvedData) => {
			const hasSucceeded = 'hasSucceeded'
			let errorMessage = ''
			let errorStatusCode = ''

			if (hasSucceeded in resolvedData && !resolvedData.hasSucceeded) {
				errorMessage = resolvedData.errorResponse.message
				errorStatusCode = resolvedData.httpStatusCode
				console.error(errorStatusCode, errorMessage)
				throw new Error(`${errorMessage} with status: ${errorStatusCode}`)
			} else {
				return resolvedData
			}
		})
}

export default fetchData
