const getUserRole = (roles) => {
	switch (typeof roles) {
		case 'string':
			return roles.split('.')[1].toUpperCase() ?? 'User'
		default:
			return roles.filter((role) => role.includes('hac'))[0].split('.')[1] ?? 'User'
	}
}

export default getUserRole
