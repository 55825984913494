import { useEffect, useState } from 'react'
import { useAuth } from 'oidc-react'
import Layout from '../layout'
import { Spinner } from '@chakra-ui/react'
import { setUserAuthorizationData } from '../globals'
import { NotFoundPage } from 'components'

const RequireAuth = ({ roles }) => {
	const [user, setUser] = useState(null)
	const [isLoading, setIsLoading] = useState(true)
	const { userManager, userData } = useAuth()

	useEffect(() => {
		const setUserData = (data) => {
			setUser(data)
			setUserAuthorizationData(data)
			setIsLoading(false)
		}

		const getUser = async () => {
			if (userData) {
				setUserData(userData)
				return
			}
			const userManagerData = await userManager.getUser()
			setUserData(userManagerData)
		}
		getUser()
	}, [userData, userManager])

	if (isLoading) {
		return <Spinner />
	}

	if (user && roles.some((item) => user.profile.role?.includes(item))) {
		return <Layout />
	}

	if (user && !roles.some((item) => user.profile.role?.includes(item))) {
		return <NotFoundPage />
	}

	userManager.signinRedirect()
	return <></>
}

export default RequireAuth
