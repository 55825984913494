import { Outlet, Link } from 'react-router-dom'
import {
	Box,
	Flex,
	Text,
	Avatar,
	Badge,
	Button,
	Menu,
	MenuButton,
	MenuList,
	MenuGroup,
	MenuItem,
	MenuDivider,
	IconButton,
	useColorMode,
	useDisclosure,
} from '@chakra-ui/react'
import Navigation from 'layout/Navigation'
import { useAtom } from 'jotai'
import { isNavigationExpanded } from 'store'
import {
	IconDocuments,
	IconLogoutCircleRLine,
	IconPatchQuestion,
	IconProfile,
	IconSettingsOutline,
	IconShieldExclamation,
} from 'icons/global/profile'
import { useAuth } from 'oidc-react'
import { SolaborateLogoCollapsed, SolaborateLogoExpanded } from 'icons/global'
import { MoonIcon, Search2Icon, SunIcon } from '@chakra-ui/icons'
import { GlobalSearchModal } from 'components'
import { getUserRole } from 'helpers'

const Layout = () => {
	const { isOpen, onOpen, onClose } = useDisclosure()
	const { colorMode, toggleColorMode } = useColorMode()
	const [expandNavigation, setExpandNavigation] = useAtom(isNavigationExpanded)
	const {
		userManager,
		userData: { profile },
	} = useAuth()

	const role = getUserRole(profile.role)

	return (
		<Flex w='100%' h='100vh'>
			<Flex
				direction='column'
				flex={expandNavigation ? 1 : '0 0 100px'}
				alignItems={expandNavigation ? 'flex-start' : 'center'}
				padding={expandNavigation ? 10 : 4}
				borderRightWidth='1px'
				overflow='hidden'
				transition='.1s'
				bg={colorMode === 'light' && 'gray.50'}>
				<Flex
					mb={10}
					flexDirection={expandNavigation ? 'row' : 'column'}
					justifyContent='space-between'
					alignItems='center'
					w='full'>
					<Link to='/dashboard'>
						{expandNavigation ? (
							<SolaborateLogoExpanded width={200} fill='#4399ff' />
						) : (
							<SolaborateLogoCollapsed width={32} height={32} fill='#4399ff' />
						)}
					</Link>
					<IconButton icon={<Search2Icon />} bg='0' onClick={onOpen} />
				</Flex>
				<Navigation />
				<Flex mt='auto' flexDirection={expandNavigation ? 'row' : 'column'} alignItems='center' width='100%' pt='4'>
					<Menu>
						<MenuButton>
							<Avatar
								size={expandNavigation ? 'md' : 'sm'}
								name={`${profile.given_name} ${profile.family_name}`}
								bg='gray.500'
								color='white'
								mr={expandNavigation ? 3 : 0}
								mb={expandNavigation ? 0 : 2}
							/>
						</MenuButton>
						<MenuList>
							<MenuGroup title='Profile' fontSize='20' color='blue.600'>
								<MenuItem icon={<IconProfile fontSize='20' />}>My Account</MenuItem>
								<MenuItem icon={<IconSettingsOutline fontSize='20' />}>Settings </MenuItem>
								<MenuItem icon={<IconShieldExclamation fontSize='20' />}>Privacy </MenuItem>
								<MenuItem
									icon={colorMode === 'dark' ? <MoonIcon fontSize='20' /> : <SunIcon fontSize='20' />}
									onClick={toggleColorMode}>
									{colorMode === 'dark' ? 'Dark Mode' : 'Light Mode'}
								</MenuItem>
								<MenuItem icon={<IconLogoutCircleRLine fontSize='20' />} onClick={() => userManager.signoutRedirect()}>
									Logout
								</MenuItem>
							</MenuGroup>
							<MenuDivider />
							<MenuGroup title='Help' fontSize='20' color='blue.600'>
								<MenuItem icon={<IconDocuments fontSize='20' />}>Docs</MenuItem>
								<MenuItem icon={<IconPatchQuestion fontSize='20' />}>FAQ</MenuItem>
							</MenuGroup>
						</MenuList>
					</Menu>
					{expandNavigation && (
						<Box>
							<Text fontSize='lg'>
								{profile.given_name} {profile.family_name}
							</Text>
							<Badge variant='outline' colorScheme='green'>
								{role}
							</Badge>
						</Box>
					)}
					<Box ml={expandNavigation ? 'auto' : 0}>
						<Button size='xs' onClick={() => setExpandNavigation((old) => !old)}>
							<span className='material-icons'>{expandNavigation ? 'unfold_less' : 'unfold_more'}</span>
						</Button>
					</Box>
				</Flex>
			</Flex>
			<Box flex={5} padding={7} h='100vh' overflow='auto'>
				<Outlet />
			</Box>
			{isOpen && <GlobalSearchModal isOpen={isOpen} onClose={onClose} />}
		</Flex>
	)
}

export default Layout
