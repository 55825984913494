import { Stack, Skeleton } from '@chakra-ui/react'

export const TableSkeleton = ({ skeletonRows }) => {
	return (
		<Stack gap='4'>
			{Array(skeletonRows)
				.fill('')
				.map((row, index) => (
					<Skeleton height='30px' key={index} />
				))}
		</Stack>
	)
}
