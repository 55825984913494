import AppRoutes from './routes';
import { Spinner } from '@chakra-ui/react';
import { useState, useEffect } from 'react';

function App() {
	const [startApplication, setStartApplication] = useState(true);

	useEffect(() => {
		const init = async () => {
			setStartApplication(true);
		};
		init();
	}, []);

	if (!startApplication) {
		return <Spinner />;
	}

	return (
		<main>
			<AppRoutes />
		</main>
	);
}

export default App;
