import { lazy, Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { NotFoundPage, ErrorPage } from 'components'
import { Route, Routes, Navigate } from 'react-router-dom'
import { RequireAuth } from 'authentication'
import Layout from 'layout/index'
import Signin from 'authentication/SignIn'
import { userRoles } from 'enums'

const Partners = lazy(() => import('pages/partners'))
const PartnerDetails = lazy(() => import('pages/partners/partnerDetails'))
const Hospitals = lazy(() => import('pages/hospitals/'))
const Users = lazy(() => import('pages/users'))
const Devices = lazy(() => import('pages/devices'))
const UploadDevices = lazy(() => import('pages/devices/UploadDevices'))
const Dashboard = lazy(() => import('pages/dashboard'))
const DeviceDetails = lazy(() => import('pages/devices/device-details'))
const DeviceCommands = lazy(() => import('pages/devices/device-details/commands'))
const DeviceLogs = lazy(() => import('pages/device-logs'))
const OsDeployments = lazy(() => import('pages/os-deployments'))
const OsDeploymentsDetails = lazy(() => import('pages/os-deployments/deployment-details'))
const OsDeploymentFloorDetails = lazy(() => import('pages/os-deployments/deployment-details/OsDeploymentFloorDetails'))
const DeviceFlavour = lazy(() => import('pages/device-flavour'))
const DeviceApps = lazy(() => import('pages/deviceApps'))
const DeviceAppsDetails = lazy(() => import('pages/deviceApps/deviceapps-details'))
const Profiles = lazy(() => import('pages/profiles'))
const ProfileDetails = lazy(() => import('pages/profiles/profile-details'))
const CreateProfile = lazy(() => import('pages/profiles/create-profile-form/CreateProfile'))
const EditProfile = lazy(() => import('pages/profiles/edit-profile-form/EditProfile'))
const HospitalFloors = lazy(() => import('pages/hospital-floors'))
const CreateOsDeployment = lazy(() => import('pages/os-deployments/create-os-deployment/CreateOsDeployment'))
const OSReleases = lazy(() => import('pages/os-releases'))
const AppReleases = lazy(() => import('pages/app-releases'))
const UploadModels = lazy(() => import('pages/upload-models'))
const MlModels = lazy(() => import('pages/ml-models'))

const AppRoutes = () => {
	return (
		<ErrorBoundary fallback={<ErrorPage />}>
			<Suspense fallback={<Layout />}>
				<Routes>
					<Route element={<RequireAuth roles={[userRoles.SUPER_USER, userRoles.HOSPITAL, userRoles.PARTNER]} />}>
						<Route path='dashboard' element={<Dashboard />} />
						<Route path='users' element={<Users />} />
						<Route path='devices' element={<Devices />} />
						<Route path='devices/:deviceId/' element={<DeviceDetails />} />
						<Route path='devices/:deviceId/deviceCommands' element={<DeviceCommands />} />
						<Route path='devicelogs' element={<DeviceLogs />} />
						<Route path='devicesupload' element={<UploadDevices />} />
						<Route path='osdeployments' element={<OsDeployments />} />
						<Route path='osdeployments/:deploymentId' element={<OsDeploymentsDetails />} />
						<Route path='osdeployments/:deploymentId/ongoing-deployment/:floorId' element={<OsDeploymentFloorDetails />} />
						<Route path='osdeployments/createDeployment' element={<CreateOsDeployment />} />
						<Route path='profiles' element={<Profiles />} />
						<Route path='profiles/:profileId' element={<ProfileDetails />} />
						<Route path='profiles/createProfile' element={<CreateProfile />} />
						<Route path='profiles/editProfile/:profileId' element={<EditProfile />} />
						<Route path='hospital-floors' element={<HospitalFloors />} />
					</Route>
					<Route element={<RequireAuth roles={[userRoles.SUPER_USER]} />}>
						<Route path='os-releases' element={<OSReleases />} />
						<Route path='app-releases' element={<AppReleases />} />
						<Route path='partners' element={<Partners />} />
						<Route path='partners/:partnerId' element={<PartnerDetails />} />
						<Route path='device-flavour' element={<DeviceFlavour />} />
						<Route path='device-apps' element={<DeviceApps />} />
						<Route path='device-apps/:appId' element={<DeviceAppsDetails />} />
						<Route path='upload-mlmodels' element={<UploadModels />} />
						<Route path='mlmodels' element={<MlModels />} />
					</Route>
					<Route element={<RequireAuth roles={[userRoles.SUPER_USER, userRoles.PARTNER]} />}>
						<Route path='hospitals' element={<Hospitals />} />
					</Route>
					<Route path='/signin-oidc' element={<Signin />} />
					<Route path='/' element={<Navigate to='/dashboard' />} />
					<Route path='*' element={<NotFoundPage />} />
				</Routes>
			</Suspense>
		</ErrorBoundary>
	)
}

export default AppRoutes
