import { ArrowForwardIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { Box, Flex, Text, Button, SkeletonText, Badge, Tooltip } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { getPopoverDeviceDetails } from 'api/devices'
import { Link } from 'react-router-dom'

const callState = {
	0: 'CONNECTING',
	1: 'CONNECTED',
	2: 'BUSY',
	3: 'DECLINED',
	4: 'OFFLINE',
	5: 'LEFT CALL',
	6: 'NOT ANSWERING',
	7: 'CANT CONNECT',
	8: 'DISRUPTED',
	9: 'RECONNECTING',
	10: 'DISCONNECTED',
	11: 'REMOVED',
}

export const DevicePopover = ({ serialNumber }) => {
	const { data, isLoading, isError } = useQuery({
		queryKey: ['device-info', serialNumber],
		enabled: !!serialNumber,
		queryFn: () => getPopoverDeviceDetails(serialNumber),
	})

	const deviceInfo = data?.result

	if (isLoading) {
		return (
			<Box padding='6' boxShadow='lg' width='full'>
				<SkeletonText mt='4' noOfLines={8} spacing='4s' skeletonHeight='2' />
			</Box>
		)
	}

	if (isError) return <Text>Something went wrong</Text>

	return (
		<Flex alignItems='center' mb={3}>
			<Box>
				<Flex alignItems='center' my='1'>
					<Text width='24' textTransform='uppercase'>
						Name :
					</Text>
					<Text fontSize='md'>{deviceInfo.deviceName}</Text>
				</Flex>
				<Flex alignItems='center' my='1'>
					<Text width='24' textTransform='uppercase'>
						Company :
					</Text>
					<Text fontSize='md'>{deviceInfo.deviceLocation ? deviceInfo.deviceLocation.company.name : 'Unassigned'}</Text>
				</Flex>
				<Flex alignItems='center' my='1'>
					<Text width='24' textTransform='uppercase'>
						Id :
					</Text>
					<Text fontSize='md'>{deviceInfo.deviceId}</Text>
				</Flex>
				<Flex alignItems='center' my='1'>
					<Text width='24' textTransform='uppercase'>
						Serial :
					</Text>
					<Text fontSize='md'> {deviceInfo.serialNumber}</Text>
				</Flex>
				<Flex alignItems='center' my='1'>
					<Text width='24' textTransform='uppercase'>
						Status :
					</Text>
					<Badge colorScheme={deviceInfo.status ? 'green' : 'gray'} px='1' rounded='sm' fontSize='sm'>
						{deviceInfo.status ? 'Online' : 'Offline'}
					</Badge>
				</Flex>
				<Flex alignItems='center' my='1'>
					<Text width='24' textTransform='uppercase'>
						Call State :
					</Text>
					<Badge colorScheme='green' px='1' rounded='sm' fontSize='sm'>
						{callState[deviceInfo.callState] || 'Unknown'}
					</Badge>
				</Flex>
				{!!deviceInfo.deviceLocation && (
					<Flex alignItems='center' gap='1' flexWrap='wrap' my='2' fontSize='md' fontWeight='medium'>
						<Tooltip hasArrow label='Company' placement='top'>
							{deviceInfo.deviceLocation.company.name}
						</Tooltip>
						<ChevronRightIcon />
						<Tooltip hasArrow label='Health System' placement='top'>
							{deviceInfo.deviceLocation.healthSystem.name}
						</Tooltip>
						<ChevronRightIcon />
						<Tooltip hasArrow label='Region' placement='top'>
							{deviceInfo.deviceLocation.region.name}
						</Tooltip>
						<ChevronRightIcon />
						<Tooltip hasArrow label='Hospital' placement='top'>
							{deviceInfo.deviceLocation.hospital.name}
						</Tooltip>
						<ChevronRightIcon />
						<Tooltip hasArrow label='Department' placement='top'>
							{deviceInfo.deviceLocation.department.name}
						</Tooltip>
						<ChevronRightIcon />
						<Tooltip hasArrow label='Floor' placement='top'>
							{deviceInfo.deviceLocation.floor.name}
						</Tooltip>
						<ChevronRightIcon />
						<Tooltip hasArrow label='Room' placement='top' wordBreak='break-all'>
							{deviceInfo.deviceLocation.room.name}
						</Tooltip>
					</Flex>
				)}

				<Link to={`/devices/${deviceInfo.serialNumber}-${deviceInfo.solHelloDeviceId}`}>
					<Button
						mt='2'
						aria-label='Go To Device'
						size='sm'
						colorScheme='telegram'
						variant='outline'
						rightIcon={<ArrowForwardIcon />}>
						Go to device
					</Button>
				</Link>
			</Box>
		</Flex>
	)
}
